import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Actions, Selector, State, StateContext, ofAction } from "@ngxs/store";
import { throwError } from "rxjs";
import { catchError, take, takeUntil, tap } from "rxjs/operators";
import { FilterAction } from "src/app/shared/components/filters/filters.component";
import { DashboardPageStateModel, DashboardPagesStateDefaults, SetError } from "src/app/shared/states/dashboard.state";
import { AddFilter, RemoveFilter, SetFilter } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { getFilterData, getUpdatedItems } from "src/app/shared/utils/utils";
import { LaborOrder } from "../../../core/interfaces/labor-order.interface";
import { LaborOrderState } from "../../../shared/enums/labor-order-state.enum";
import { LaborOrdersFilters } from "../interfaces/labor-orders-filters.interface";
import { OrdersService } from "../services/orders.service";
import { CreateLaborOrder, DuplicateOrder, LaborOrderChangeState, LoadOrders, RemoveOrder, SaveAndSendLaborOrder, SaveDispatchNotes, SetCurrentOrder, UpdateLaborOrder, UpdateLaborOrdersFilters } from "./order.actions";

export class OrderStateModel extends DashboardPageStateModel {
  items: Array<LaborOrder>;
  currentOrder: string;
  totalOrders: number;
  filters: LaborOrdersFilters;
}

@State<OrderStateModel>({
  name: "order",
  defaults: { 
    ...DashboardPagesStateDefaults, 
    currentOrder: "", 
    totalOrders: null,
    filters: null
  }
})
@Injectable()
export class OrderState {

  constructor(
    private orderService: OrdersService,
    private actions$: Actions
  ) {}

  @Selector() static orders(state: OrderStateModel) { return state.items; }
  @Selector() static loading(state: OrderStateModel) { return state.loading; }
  @Selector() static saving(state: OrderStateModel) { return state.saving; }
  @Selector() static totalOrders(state: OrderStateModel) { return state.totalOrders; }
  @Selector() static currentOrder(state: OrderStateModel) { return state.currentOrder; }
	@Selector() static filters(state: OrderStateModel) { return state.filters; }

  @Action(SetCurrentOrder)
  setCurrentOrder(ctx: StateContext<OrderStateModel>, { orderId }) {
    ctx.patchState({ currentOrder: orderId });
  }

  @Action(LoadOrders)
  loadOrders(ctx: StateContext<OrderStateModel>, { skipCount, maxResult }) {
    ctx.patchState({
      items: [],
      loading: true
    });
    
    const filters: LaborOrdersFilters = ctx.getState().filters || {};
    return this.orderService.getOrders(skipCount, maxResult, filters).pipe(
      tap(
        res => {
          ctx.patchState({
            items: res.items,
            loading: false,
            totalOrders: res.totalCount
          });

          if (!filters.isFiltered) {
            const terminalsArray = getFilterData(res, "AvailableTerminals");
            if (terminalsArray) {
              ctx.dispatch(new SetFilter("filteredTerminals", terminalsArray));
            }

            const employerssArray = getFilterData(res, "AvailableEmployers");
            if (employerssArray) {
              ctx.dispatch(new SetFilter("filteredEmployers", employerssArray));
            }
          }
        },
        error => {
          ctx.patchState({
            items: [],
            loading: false
          });
          ctx.dispatch(new SetError({ loading: true }));
        }
      ),
      takeUntil(this.actions$.pipe(ofAction(FilterAction)))
    );
  }

  @Action(CreateLaborOrder)
  createOrder(ctx: StateContext<OrderStateModel>, { order, callback }) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });

    return this.orderService.create(order).subscribe(
      res => {
        callback();

        ctx.dispatch(
          new AddFilter("filteredTerminals", {
            key: res.terminal.id,
            value: res.terminal.name
          })
        );

        ctx.dispatch(
          new AddFilter("filteredEmployers", {
            key: res.employer.id,
            value: res.employer.name
          })
        );

        ctx.patchState({ items: getUpdatedItems(res, state.items), saving: false });
      },
      error => {
        callback(error);
        ctx.patchState({ items: [], saving: false });
        ctx.dispatch(new SetError({ saving: true }));
      }
    );
  }

  @Action(UpdateLaborOrder)
  updateOrder(ctx: StateContext<OrderStateModel>, { order, callback }) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });

    return this.orderService.update(order).subscribe(
      res => {
        callback();
        ctx.patchState({ items: getUpdatedItems(res, state.items), saving: false });
      },
      error => {
        callback(error);
        ctx.patchState({ items: [], saving: false });
        ctx.dispatch(new SetError({ saving: true }));
      }
    );
  }

  @Action(SaveAndSendLaborOrder)
  saveAndSendLaborOrder(ctx: StateContext<OrderStateModel>, action: SaveAndSendLaborOrder) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });
    
    return this.orderService.update(action.order).pipe(
      tap(
        (response) => {
          this.orderService.changeStatus(action.order.id, LaborOrderState.Ordered).pipe(
            take(1),
            tap(
              (order) => {
                action.callback(null);
                ctx.patchState({ saving: false });
              },
              (error) => {
                action.callback(error);
                ctx.patchState({ saving: false });
                ctx.dispatch(new SetError({ saving: error }));
              }
            )
          ).subscribe();
        },
        (error) => {
          action.callback(error);
          ctx.patchState({ saving: false });
          ctx.dispatch(new SetError({ saving: true }));
        }
      )
    );
  }

  @Action(LaborOrderChangeState)
  laborOrderChangeState(ctx: StateContext<OrderStateModel>, { orderId, orderState, callback }) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });
    return this.orderService.changeStatus(orderId, orderState).subscribe(
      order => {
        callback();
        ctx.patchState({
          items: getUpdatedItems(order, state.items),
          saving: false
        });
      },
      error => {
        callback(error);
        ctx.patchState({ saving: false });
        ctx.dispatch(new SetError({ saving: error }));
      }
    );
  }

  @Action(RemoveOrder)
  remove(ctx: StateContext<OrderStateModel>, { order, callback }) {
    const state = ctx.getState();
    return this.orderService.remove(order.id).subscribe(
      () => {
        callback();
        const updatedOrders = state.items.filter(item => {
          return item.id !== order.id;
        });
        ctx.patchState({ items: updatedOrders, totalOrders: updatedOrders.length });

        ctx.dispatch(
          new RemoveFilter("filteredTerminals", {
            key: order.terminal.id,
            value: order.terminal.name
          })
        );

        ctx.dispatch(
          new RemoveFilter("filteredEmployers", {
            key: order.employer.id,
            value: order.employer.name
          })
        );
      },
      error => {
        callback();
        ctx.dispatch(new SetError({ removing: error }));
      }
    );
  }

  @Action(SaveDispatchNotes)
  saveDispatchNotes(ctx: StateContext<OrderStateModel>, { orderId, dispatchNotes, callback }) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });
    return this.orderService.saveDispatchNote(orderId, dispatchNotes).subscribe(
      order => {
        const foundOrder = JSON.parse(JSON.stringify(state.items.find(o => o.id === order.id)));
        foundOrder.dispatchNotes = order.dispatchNotes;
        callback();
        ctx.patchState({
          items: getUpdatedItems(foundOrder, state.items),
          saving: false
        });
      },
      error => {
        callback(error);
        ctx.patchState({ saving: false });
        ctx.dispatch(new SetError({ saving: error }));
      }
    );
  }

  @Action(DuplicateOrder)
  duplicateOrder(ctx: StateContext<OrderStateModel>, action: DuplicateOrder) {
    ctx.patchState({ saving: true });
    return this.orderService.duplicateOrder(action.orderId, action.payload).pipe(
      tap((response) => {
        ctx.patchState({ saving: false });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ saving: false });
        return throwError(() => err);
      })
    );
  }

  @Action(UpdateLaborOrdersFilters)
	updateFilters(ctx: StateContext<OrderStateModel>, { filters }) {
		ctx.patchState({ filters });
	}
}

